export default {
  "Français": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "Anglais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Nouvelle recommandation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle recommandation musicale"])},
  "Aucune recommandation enregistrée n'a été trouvée.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune recommandation enregistrée n'a été trouvée."])},
  "Supprimer les sauvegardes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les sauvegardes"])},
  "Vous n'avez créé aucune recommandation !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez créé aucune recommandation musicale !"])},
  "Commencer en cliquant sur le bouton !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer en cliquant sur le bouton !"])},
  "Sélectionner 3 genres de musique :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner 3 genres de musique :"])},
  "Rechercher un genre dans la liste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un genre dans la liste"])},
  "Aucun genre musical trouvé.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun genre musical trouvé."])},
  "Vos genres sélectionnés :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos genres sélectionnés :"])},
  "Vos musiques sélectionnées :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos musiques sélectionnées :"])},
  "Vous n'avez sélectionné aucun genre.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez sélectionné aucun genre."])},
  "Sélectionner 3 morceaux de musique :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner jusqu'à 3 morceaux de musique :"])},
  "Rechercher un morceau de musique dans la liste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un morceau de musique dans la liste"])},
  "Aucune musique trouvée.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune musique trouvée."])},
  "Réinitialiser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "Vous n'avez sélectionné aucune musique.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez sélectionné aucune musique."])},
  "Sélectionner le nombre de recommandations que vous souhaitez recevoir de Muse :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le nombre de recommandations que vous souhaitez recevoir de Muse :"])},
  "Vous avez choisi ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez choisi "])},
  "recommendation(s) par Muse.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recommendation(s) par Muse."])},
  "Modifier les recommandations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les recommandations musicales."])},
  "Modifier les musiques": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les musiques"])},
  "Précédent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
  "Annuler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "Suivant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "Créer la recommandation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer la recommandation"])},
  "Étape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape"])},
  "Résumé": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé"])},
  "Erreur cette étape n'existe pas !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur, cette étape n'existe pas !"])},
  "Genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "Année": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  " musiques :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" musiques :"])},
  "Noter la recommandation de Muse 1 :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter la recommandation musicale de Muse 1 :"])},
  "Noter la recommandation de Muse 2 :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter la recommandation musicale de Muse 2 :"])},
  "Muse 1 vous recommande ces morceaux de musique :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muse 1 vous recommande ces morceaux de musique :"])},
  "Muse 2 vous recommande ces morceaux de musique :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muse 2 vous recommande ces morceaux de musique :"])},
  "Envoyer vos avis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer vos avis"])},
  "Bienvenue sur Muse, un projet de recommendations musicales.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur Muse, un projet de recommendations musicales."])},
  "Ce site web ne récolte aucune donnée utilisateur personnelle et n'utilise aucun cookie. Les avis sont envoyés annonymement sans inclure d'information permettant de tracer l'utilisateur.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce site web ne récolte aucune donnée utilisateur personnelle et n'utilise aucun cookie. Les avis sont envoyés annonymement sans inclure d'information permettant de tracer l'utilisateur."])},
  "Il est peu probable que les musiques sélectionnables vous soient connues. Utilisez vos genres préférés pour sélectionner des musiques qui vous plaisent !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est peu probable que les musiques sélectionnables vous soient connues. Utilisez vos genres préférés pour sélectionner des musiques qui vous plaisent !"])},
  "Toutes les musiques sont disponibles sur le site: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les musiques sont disponibles sur le site: "])},
  "Pensez à bien utiliser toute l'échelle pour noter les recommendations.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensez à bien utiliser toute l'échelle pour noter les recommendations."])},
  "La recommendation ne vous plais pas du tout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recommendation ne vous plais pas du tout."])},
  "La recommendation pourrait être meilleure ou plus pertinente.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recommendation pourrait être meilleure ou plus pertinente."])},
  "La recommendation vous plais énormément.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recommendation vous plais énormément."])},
  "J'ai compris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai compris"])},
  "Informations sur Muse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur Muse"])}
}