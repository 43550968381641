export default {
  "Français": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "Anglais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Nouvelle recommandation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New musical recommendation"])},
  "Aucune recommandation enregistrée n'a été trouvée.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No saved recommendation was found."])},
  "Supprimer les sauvegardes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete backups"])},
  "Vous n'avez créé aucune recommandation !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't created any musical recommendation !"])},
  "Commencer en cliquant sur le bouton !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start by clicking the button !"])},
  "Sélectionner 3 genres de musique :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select up to 3 music genres"])},
  "Rechercher un genre dans la liste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup a genre in the list"])},
  "Aucun genre musical trouvé.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No musical genre found"])},
  "Vos genres sélectionnés :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your selected genres :"])},
  "Vos musiques sélectionnées :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your selected songs :"])},
  "Vous n'avez sélectionné aucun genre.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not selected any genre"])},
  "Sélectionner 3 morceaux de musique :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select up to 3 songs"])},
  "Rechercher un morceau de musique dans la liste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup a song in the list"])},
  "Aucune musique trouvée.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No song found"])},
  "Réinitialiser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "Vous n'avez sélectionné aucune musique.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not selected any song"])},
  "Sélectionner le nombre de recommandations que vous souhaitez recevoir de Muse :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the desired number of musics recommendations you would like to receive from Muse :"])},
  "Vous avez choisi ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected: "])},
  "recommendation(s) par Muse.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["music(s) recommendation(s) from Muse."])},
  "Modifier les recommandations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit musics recommendations"])},
  "Modifier les musiques": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit songs"])},
  "Précédent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "Annuler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Suivant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "Créer la recommandation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start recommendation"])},
  "Étape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
  "Résumé": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
  "Erreur cette étape n'existe pas !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, this step does not exist !"])},
  "Genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "Année": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  " musiques :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" songs :"])},
  "Noter la recommandation de Muse 1 :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Muse 1's music recommendation"])},
  "Noter la recommandation de Muse 2 :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Muse 2's music recommendation"])},
  "Muse 1 vous recommande ces morceaux de musique :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muse 1 recommends you the following songs: "])},
  "Muse 2 vous recommande ces morceaux de musique :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muse 2 recommends you the following songs: "])},
  "Envoyer vos avis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send your reviews"])},
  "Bienvenue sur Muse, un projet de recommendations musicales.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome on Muse, a project for musical recommendations."])},
  "Ce site web ne récolte aucune donnée utilisateur personnelle et n'utilise aucun cookie. Les avis sont envoyés annonymement sans inclure d'information permettant de tracer l'utilisateur.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website does not collect any personal user information and does not use any cookie.\nRatings are sent annonymously without including any information permitting to trace users."])},
  "Il est peu probable que les musiques sélectionnables vous soient connues. Utilisez vos genres préférés pour sélectionner des musiques qui vous plaisent !": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is unlikely that you will know the selectable songs.\nUse your favorite genres to select songs that you enjoy !"])},
  "Toutes les musiques sont disponibles sur le site: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All songs used are available at: "])},
  "Pensez à bien utiliser toute l'échelle pour noter les recommendations.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use the full scale to rate the recommendations."])},
  "La recommendation ne vous plais pas du tout.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You really don't like the recommendation."])},
  "La recommendation pourrait être meilleure ou plus pertinente.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The recommendation could be better or more relevant."])},
  "La recommendation vous plais énormément.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You really enjoy the recommendation."])},
  "J'ai compris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand"])},
  "Informations sur Muse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about Muse"])}
}